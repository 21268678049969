<template>
  <div class="leaderboard">
    <div class="container-fluid">
      <div class="filter">
        <div class="container">
          <ul class="text-white hidden-sm-and-down">
            <li
              v-for="(tab) in tabs"
              :id="`activate-${tab.id}`"
              :key="tab.id"
              :class="{ active : activeTab == tab.id }"
              @click="activeTab = tab.id"
            >
              {{ tab.title }}
            </li>
            <span
              v-loading="campaignIsLoading"
              class="tab-loader"
            ></span>
          </ul>
          <div class="mobile-filter hidden-md-and-up">
            <el-select v-model="activeTab">
              <el-option
                v-for="(tab) in tabs"
                :key="tab.id"
                :label="tab.title"
                :value="tab.id"
                @click="activeTab = tab.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <keep-alive>
        <global-leaderboard v-if="activeTab == 1"></global-leaderboard>
        <campaign-leaderboard-iterator
          v-for="(campaign) in campaigns"
          v-if="activeTab == campaign.id+1"
          :key="campaign.id"
          :campaign="campaign"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import GlobalLeaderboard from '@/modules/leaderboard/components/global-leaderboard'
import CampaignLeaderboardIterator from '@/modules/leaderboard/components/campaign-leaderboard-iterator'

export default {
  name: 'LeaderboardPage',

  components: {
    GlobalLeaderboard,
    CampaignLeaderboardIterator
  },

  data: () => ({
    tabs: [
      {
        id: 1,
        title: 'Global',
        tags: [],
      }
    ],
    campaigns: [],
    campaignIsLoading: false,
    activeTab: 1,
    isSecondaryActive: 'team'
  }),
}
</script>

<style lang="scss">
.leaderboard {
  .filter {
    height: 55px;
    background-color: $feed-banner-primary-bg-color;

    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;
      padding-top: 11.5px;
      li {
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        padding: 7.5px 33px;
        border-radius: 16.5px;
        margin-right: 12px;
        cursor: pointer;
        transition: .3s ease-in;
        @media (max-width: 1200px) {
          padding: 7.5px 22px;
        }
        @media (max-width: 991px) {
          padding: 7.5px 10px;
          font-size: 12px;
        }
      }
      .active {
        background-color: $feed-banner-primary-bg-active-color;
        color: $feed-banner-primary-fg-active-color;
      }
      .tab-loader {
          min-width: 50px;
          .el-loading-mask .circular circle {
              stroke: #FFFFFF;
          }
          .el-loading-spinner {
              margin-top: -15px;
              .circular {
                  width: 32px;
                  height: 32px;
              }
          }
      }
    }
    .mobile-filter {
      .el-select {
        padding-top: 7px;
        color: $white-color;
        cursor: pointer;
        display: block;
        input {
          background: $white-color;
          color: $primary-color;
          border: none;
          border-radius: 4px;
          padding: 12px;
          height: auto;
          line-height: normal;
          font-size: 13px;
          font-weight: bold;
        }
        i {
          margin-left: 90px;
          color: $white-color;
          font-weight: 900;
          @media (max-width: 416px) {
            margin-left: 0;
            float: right;
          }
        }
      }
    }
  }

  .top-header {
    &.campaign {
      margin-bottom: -55px
    }
    background-color: $primary-color;
    padding: 27px 0 81px 0;
    color: $white-color;
    .title {
      h2 {
        color: $white-color;
        margin: 0;
      }
    }
    .card {
      background: rgba(0, 0, 0, 0.23);
      margin-top: 23px;
      padding: 13px 20px 23px 20px;
      border-radius: 6px;
      color: $white-color;
      h3 {
        color: $white-color;
        font-weight: bold;
      }
      .user-block {
        background-color: $primary-color;
        position: relative;
        padding: 20px 44px;
        border-radius: 6px;
        overflow: auto;
        display: flex;
        align-items: center;
        text-align: center;
        @media screen and (max-width: 425px) {
          display: block;
        }
        .white-loader {
          .el-loading-mask .circular circle {
            stroke: $white-color;
          }
        }
      }
    }
  }
  .leaderboard-table {
    position: relative;
    h2 {
      margin: 0;
      position: absolute;
      left: 35px;
      top: 21px;
    }
    .sort {
      margin-top: 20px;
      float: right;
      .txt {
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
      }
      .drop-sel {
          margin-right: 25px;
          display: inline-block;
          @media (max-width: 416px) {
            margin-right: 0;
          }
          .el-select {
            display: block;
            cursor: pointer;
            input {
              color: $primary-color;
              border: none;
              border-radius: 0;
              border-bottom: 1px solid $border-color;
              // padding: 0 60px 6px 6px;
              height: auto;
              // line-height: normal;
              font-size: 13px;
              font-weight: bold;
            }
            .el-loading-spinner {
              margin-top: -14px;
            }
            i {
              color: $primary-color;
              font-weight: 900;
              @media (max-width: 416px) {
                margin-left: 0;
                float: right;
              }
            }
          }
        }
      }
    .campaign-leaderboard {
        background-color: $white-color;
        border-radius: 4px;
        padding-top: 55px;
        margin-top: -40px;

        .search {
          width: 200px;
          float: right;
          margin-top: -42px;
          margin-right: 25px;
          color: $primary-color;
          input {
            border: none;
            background-color: transparent;
            border-radius: 0;
            border-bottom: 1px solid $border-color;
            padding: 0 60px 6px 6px;
            height: auto;
            line-height: normal;
            font-size: 13px;
            font-weight: bold;
          }
          .el-input__suffix {
            right: -5px;
            i {
              color: $primary-color;
              font-size: 16px;
            }
          }
          .el-loading-spinner {
            text-align: right;
            right: 20px;
            svg {
              margin-top: 5px;
              width: 20px;
              height: 20px;
            }
          }
        }

        .filter-and-sort {
          width: 100%;
          height: 55px;
          background: #ECECEC;
          margin-top: 0px;
          float: none;
          align-items: center;

          .txt {
            line-height: 55px;
            margin-left: 30px;
            font-size: 12px;
            font-weight: bold;
            display: inline-block;
          }

          .tag-filter {
            .filter {
              display: inline-block;
              margin-left: 10px;
              background-color: transparent;
              .active {
                background-color: $primary-color;
                color: $white-color;
              }
            }
          }
        }

        .table-content {
          background: none;
        }
    }
    .container {
      position: relative;
    }
    .table-content {
      background-color: $white-color;
      padding: 21px 20px 38px 20px;
      &.is-campaign {
        margin-top: -107px;
      }
      margin-top: -57px;
      margin-bottom: 57px;
      border-radius: 4px;
      @media screen and (max-width: 1091px) {
        overflow-x: auto;
      }
      @media screen and (max-width: 425px) {
        overflow-x: auto;
      }

      #leaderboard-table {
        @media screen and (max-width: 1091px) {
          width: 150%;
          overflow-x: auto;
        }
        @media screen and (max-width: 768px) {
          width: 200%;
          overflow-x: auto;
        }
        @media screen and (max-width: 425px) {
          width: 330%;
          overflow-x: auto;
        }
      }
      table {
        margin-top: 58px;
        width: 100%;
        border-collapse: collapse;
        &:before {
          display: none;
        }
        thead {
          text-align: left;
          th {
            padding: 8px 20px;
            font-size: 12px;
            color: $white-color;
            @media screen and (max-width: 768px) {
              div {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
          &  th:nth-child(1) {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          & th:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
          tr:nth-child(1) th {
            background-color: $body-text-color;
          }
        }
        tbody {
          color: $body-text-color;
          tr {
            &:nth-child(even) {
              background-color: #f9f9f9;
            }
            td {
              padding: 9px 20px;
              font-size: 27px;
              border: none;
              @media screen and (max-width: 320px) {
                font-size: 14px !important;
              }
              &:nth-child(1) {
                font-size: 16px;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
              }
              // &:nth-child(2), &:nth-child(3) {
              //   font-size: 18px;
              // }
              &:last-child{
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
              }
              &:not(:first-child) {
                color: $primary-color;
              }
            }
          }
        }
        .table-loader {
          //left: 50%;
          //margin-right: 0%;
          //transform: translate(30%, 0%);
          &.el-loading-parent--relative {
            position: inherit !important;
            .el-loading-spinner {
              margin-top: 0;
            }
          }
        }

      }
    }
    .leaderboard-table-pagination {
      text-align: center;
      margin-bottom: 57px;
    }
  }
  #team-breakdown-table{
    .no-data{
      text-align: center;
    }
  }
  .team-information{
    background: none!important;
    border: none;
    padding: 0!important;
    cursor: pointer;
    font-size: 18px;
    color: $primary-color;
  }
  .team-information:hover{
    text-decoration: underline;
  }
}
</style>
